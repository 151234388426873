var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: { persistent: "", "max-width": "900px", scrollable: "" },
      model: {
        value: _vm.show,
        callback: function ($$v) {
          _vm.show = $$v
        },
        expression: "show",
      },
    },
    [
      _c(
        "v-card",
        [
          _c(
            "v-card-title",
            {
              staticClass:
                "headline d-flex justify-space-between px-5 py-3 v-modal-header",
            },
            [
              _c("h5", { staticClass: "text-h6" }, [
                _vm._v(_vm._s(_vm.$t("selectAppsAR.chooseApp"))),
              ]),
              _c("v-icon", { on: { click: _vm.close } }, [_vm._v("mdi-close")]),
            ],
            1
          ),
          _c(
            "v-card-text",
            { staticClass: "pa-3" },
            [
              _c(
                "v-container",
                [
                  _c(
                    "v-form",
                    {
                      staticClass: "apps adgroup",
                      model: {
                        value: _vm.valid,
                        callback: function ($$v) {
                          _vm.valid = $$v
                        },
                        expression: "valid",
                      },
                    },
                    [
                      _c(
                        "v-row",
                        { staticClass: "ma-0" },
                        [
                          _c(
                            "v-col",
                            {
                              staticClass: "py-0",
                              attrs: { cols: "12", md: "6", sm: "6" },
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "search-list d-flex align-center justify-space-between pt-2 pb-2",
                                },
                                [
                                  _c("label", { staticClass: "pt-0" }, [
                                    _vm._v(
                                      _vm._s(_vm.totalApps) +
                                        " " +
                                        _vm._s(_vm.$t("selectAppsAR.apps"))
                                    ),
                                  ]),
                                ]
                              ),
                            ]
                          ),
                          _c(
                            "v-col",
                            {
                              staticClass: "py-0",
                              attrs: { cols: "12", md: "6", sm: "6" },
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "selected-groups-col d-flex align-center justify-space-between pt-2 pb-2",
                                },
                                [
                                  _c("label", [
                                    _vm._v(
                                      _vm._s(_vm.totalSelectedApps) +
                                        " " +
                                        _vm._s(
                                          _vm.$t("selectAppsAR.appSelected")
                                        )
                                    ),
                                  ]),
                                  _vm.totalSelectedApps
                                    ? _c(
                                        "v-btn",
                                        {
                                          attrs: {
                                            depressed: "",
                                            text: "",
                                            small: "",
                                            color: "error",
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.clearAll()
                                            },
                                          },
                                        },
                                        [
                                          _c(
                                            "v-icon",
                                            { attrs: { small: "" } },
                                            [_vm._v("mdi-window-close")]
                                          ),
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.$t("selectAppsAR.clearAll")
                                              ) +
                                              " "
                                          ),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                ],
                                1
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-row",
                        { staticClass: "ma-0" },
                        [
                          _c(
                            "v-col",
                            {
                              staticClass: "pa-0",
                              attrs: { cols: "12", md: "6", sm: "6" },
                            },
                            [
                              _c(
                                "v-card",
                                {
                                  staticClass:
                                    "v-height-300 no-border-left pa-2",
                                  attrs: { outlined: "", tile: "" },
                                },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "boxes" },
                                    _vm._l(_vm.appsWithOrg, function (item) {
                                      return _c("v-checkbox", {
                                        key: item.adamId,
                                        staticClass: "mt-0",
                                        attrs: {
                                          value: item,
                                          label: item.appName,
                                          color: "primary",
                                          "hide-details": "",
                                          multiple: "",
                                        },
                                        on: {
                                          change: function ($event) {
                                            return _vm.oncheck()
                                          },
                                        },
                                        model: {
                                          value: _vm.selectedApps,
                                          callback: function ($$v) {
                                            _vm.selectedApps = $$v
                                          },
                                          expression: "selectedApps",
                                        },
                                      })
                                    }),
                                    1
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            {
                              staticClass: "pa-0",
                              attrs: { cols: "12", md: "6", sm: "6" },
                            },
                            [
                              _c(
                                "v-card",
                                {
                                  staticClass:
                                    "v-height-300 no-border-left no-border-right pa-3",
                                  attrs: { outlined: "", tile: "" },
                                },
                                [
                                  _vm._l(_vm.selectedApps, function (app) {
                                    return [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "d-flex align-center justify-space-between mb-2",
                                        },
                                        [
                                          _c("label", [
                                            _vm._v(
                                              " " + _vm._s(app.appName) + " "
                                            ),
                                          ]),
                                        ]
                                      ),
                                    ]
                                  }),
                                ],
                                2
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-card-actions",
            { staticClass: "justify-end mt-0 pa-5 no-border-top" },
            [
              _c(
                "v-btn",
                {
                  attrs: {
                    width: "127",
                    color: "grey",
                    depressed: "",
                    outlined: "",
                  },
                  on: { click: _vm.close },
                },
                [_vm._v(" " + _vm._s(_vm.$t("selectAppsAR.cancel")) + " ")]
              ),
              _c(
                "v-btn",
                {
                  attrs: {
                    width: "127",
                    color: "primary",
                    depressed: "",
                    disabled: !_vm.totalSelectedApps,
                  },
                  on: { click: _vm.save },
                },
                [_vm._v(" " + _vm._s(_vm.$t("selectAppsAR.apply")) + " ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }