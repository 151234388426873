



































































































































































































// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import * as _ from 'lodash';
import { APPSLIST_WITH_CAMPAIGNS } from '../../utils/apollo-queries';
import Vue from 'vue';

export default Vue.extend({
  name: 'SelectCampaign',
  props: {
    openSelectCampaign: {
      // this object also includes now property which is current timestamp.
      // this is to trigger change detection even if values are unchanged in the passed object.
      type: Object,
      required: false,
      default() {
        return {};
      },
    },
  },

  computed: {
    a: function (): string {
      return 'a';
    },
  },

  watch: {
    openSelectCampaign: function (data) {
      if (data.arIndex != null) {
        this.arIndex = data.arIndex;
      } else {
        this.arIndex = null;
      }
      this.getAppsListWithCampaigns()
      this.show = true;
    },
  },

  data() {
    return {
      show: false,
      appsListWithCampaigns: null,
      listedCampaign: null,
      totalCampaigns: 0,
      totalSelectedCampaigns: 0,
      panel: [],
      valid: true,
      arIndex: null,
      fullscreenLoader: null,
    };
  },

  methods: {
    deleteCamp(c){
      this.appsListWithCampaigns.map((app) =>
        app.campaigns.map((listedCampaign) => {
          if(listedCampaign.id === c.id) listedCampaign.selected = false
        })
      );
      this.oncheck(c)
    },
    listCampaign(campaign) {
      this.listedCampaign = campaign;
    },

    oncheck(): void {
      this.totalSelectedCampaigns = this.appsListWithCampaigns
        .map((app) =>
          app.campaigns.reduce(
            (p, currrent) => (currrent.selected === true ? 1 : 0 + p),
            0
          )
        )
        .reduce((p, c) => p + c);
    },

    clearAll() {
      this.totalSelectedCampaigns = 0;

      this.appsListWithCampaigns.map((app) =>
        app.campaigns.map((listedCampaign) => (listedCampaign.selected = false))
      );
    },

    save() {
      const toEmit = _.cloneDeep(this.appsListWithCampaigns);
      toEmit.forEach(
        (app) =>
          (app.campaigns = app.campaigns
            .filter((campaign) => campaign.selected)
            // need to omit property 'selected' since it's only used for binding in component's state
            .map((campaign) => _.omit(campaign, ['selected'])))
      );
      if (this.arIndex != null) {
        const toEmitWithIndex = {
          data: toEmit,
          index: this.arIndex,
        };
        this.$emit('onCampaignSelection', toEmitWithIndex);
      } else {
        this.$emit('onCampaignSelection', toEmit);
      }
      this.close();
    },

    close() {
      this.show = false;
      this.arIndex = null;
      this.appsListWithCampaigns = null;
      this.listedCampaign = null;
      this.totalCampaigns = 0;
      this.totalSelectedCampaigns = 0;
    },

    expandAll() {
      this.panel = Array.from(Array(this.appsListWithCampaigns.length).keys());
    },

    collapseAll() {
      this.panel = [];
    },

    getAppsListWithCampaigns() {
      this.fullscreenLoader = this.$loading.show();
      this.$apollo
        .query({
          query: APPSLIST_WITH_CAMPAIGNS,
          variables: {
            at: Date.now(),
          },
        })
        .then((res) => {
          this.fullscreenLoader.hide();
          this.appsListWithCampaigns = _.cloneDeep(res.data.getAppsListWithCampaigns);
          this.totalCampaigns = this.appsListWithCampaigns.reduce(
            (p, c) => p + c.campaigns.length,
            0
          );
        })
        .catch((error) => {
          this.fullscreenLoader.hide();
          this.$notify.error('Error fetching campaigns. Try later');
        });
    },
  },

  // apollo: {
  //   getAppsListWithCampaigns: {
  //     query: APPSLIST_WITH_CAMPAIGNS,
  //     variables: {
  //       at: Date.now(),
  //     },
  //     skip() {
  //       return !this.show;
  //     },
  //     update({ getAppsListWithCampaigns }) {
  //       this.appsListWithCampaigns = _.cloneDeep(getAppsListWithCampaigns);
  //       this.totalCampaigns = this.appsListWithCampaigns.reduce(
  //         (p, c) => p + c.campaigns.length,
  //         0
  //       );
  //     },
  //   },
  // },
});
